import React, { useEffect, useState } from "react";

const UnverifiedTab = () => {
        return (
                <div className="unverified-tab">
                        <div className="unverified-tab-title">Unavailable</div>
                        <div className="unverified-tab-content">
                                <div className="unverified-tab-message">The portal is currently undergoing maintenance or administration.</div>
                                <div className="unverified-tab-message">Please check back soon.</div>
                        </div>
                </div>
        );
}

export default UnverifiedTab;