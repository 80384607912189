import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import ContentArea from "./ContentArea";
import UnverifiedTab from "./UnverifiedTab";
import MaintenanceTab from "./MaintenanceTab";
import { useTeacherContext } from "../contexts/TeacherContext";
import axios from "axios";

const MainContent = () => {
        const teacher = useTeacherContext();
        const verified = teacher.verified;
        const [maintenance, setMaintenance] = useState(false);
        const [loading, setLoading] = useState(true);

        const checkMaintenance = async () => {
                try {
                        const res = await axios.get("/api/utils/maintenance", {
                                withCredentials: true
                        });

                        if (res.data) {
                                setMaintenance(true);
                        } else {
                                setMaintenance(false);
                        }

                        setLoading(false);
                } catch (err) {
                        console.log(err);
                };
        };

        useEffect(() => {
                checkMaintenance();
        }, []);

        if (verified) {
                if (maintenance) {
                        return (
                                <MaintenanceTab />
                        );
                } else {
                        if (loading) {
                                return <div>Loading...</div>;
                        } else {
                                return (
                                        <div className="body">
                                                <Menu />
                                                <ContentArea />
                                        </div>
                                );
                        }
                }
        } else {
                return (
                        <UnverifiedTab />
                )
        }

}

export default MainContent;